<template>
  <div class="Background">
    <img
      class="backgroundImg"
      id="underlay"
      :src="clearImgSrc"
      alt="What an clear image!"
      style="z-index: -100"
    />
    <img
      :class="'foregroundImg ' + (transparent ? 'transparent' : '')"
      @click="toggleTransparent()"
      id="underlay"
      :src="triImgSrc"
      alt="What an clear image!"
      style="z-index: -10"
    />
  </div>
  <main>
    <div>
      <div :class="transparent ? 'visible' : ''" id="comebacktomorrow">
        Morgen gibt's ein neues Bild!
      </div>
      <!-- <button v-on:click="nextImg()" type="button" class="button">
        Try me Too!
      </button> -->
      <button v-on:click="toggleTransparent()" type="button" class="button">
        {{ buttonText }}
      </button>
    </div>
    <h1>Ich bin Edith!</h1>
  </main>
</template>

<script>
import { getImgUrl } from "@/utils/utils.js";
export default {
  data() {
    return {
      prefix: this.$store.state.prefix,
      dayNumber: 6666,
      buttonText: "",
      clearImgSrc: "",
      triImgSrc: "",
      showImg: false,
      transparent: false,
    };
  },
  /**
   * Initialize vars before mount in order to display image for given date
   * as well as button text.
   */
  beforeMount() {
    this.dayNumber = daysIntoYear(new Date());
    this.buttonText = this.$store.state.imageMap[this.dayNumber];
    this.imgOfTheDay();
  },
  methods: {
    toggleTransparent() {
      this.transparent = !this.transparent;
    },
    /**
     * Helper function used to provide the next image for debugging.
     */
    nextImg() {
      this.dayNumber += 1;
      if (this.dayNumber >= 366) {
        this.dayNumber = 1;
      }
      this.buttonText = this.$store.state.imageMap[this.dayNumber];
      this.imgOfTheDay();
    },
    /**
     * 'imgOfTheDay' takes filename for current dayNumber
     * and prepares filename for loading (remove all whitespaces
     * for correct filename). Sets filenames for altered (svg) and
     * unaltered (jpg) image version.
     */
    imgOfTheDay() {
      // get correct formated filename for given daynumber
      const filename = stripOfAllWhiteSpaces(
        this.$store.state.imageMap[this.dayNumber]
      );
      // set image for altered and unaltered version
      this.triImgSrc = getImgUrl(filename + ".svg");
      this.clearImgSrc = getImgUrl(filename + ".jpg");
    },
  },
};

/**
 * 'stripOfAllWhiteSpaces' removes all whitespaces in a string.
 */
function stripOfAllWhiteSpaces(myString) {
  return myString.replace(/\s+/g, "");
}

/**
 * 'daysIntoYear' calculates the length of the current year in
 * days.
 * @param {Date} date Dateobject for which the length shall be calulated.
 */
function daysIntoYear(date) {
  const lengthOfYear =
    (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
      Date.UTC(date.getFullYear(), 0, 0)) /
    24 /
    60 /
    60 /
    1000;
  return lengthOfYear;
}
</script>

<style scoped>
main {
  min-height: 100vh;
  padding: 25px;
  color: rgba(243, 255, 238, 0.709);
}
#myContent {
  height: 100%;
  width: auto;
}
h1 {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 80px;
  font-weight: bold;
  letter-spacing: -3px;
  line-height: 1;
  text-align: center;
  text-shadow: 0px 2px 3px rgb(77, 77, 77);
  opacity: 0.6;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.button {
  background-color: #4caf6d;
  border-radius: 8px 8px 8px 8px;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.671);
  font-size: 16px;
  margin: 10px;
  z-index: 5;
}
.Background img {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
  max-height: 100vh;
  object-fit: cover;
  object-position: cover;
  -webkit-transition: opacity 5s ease-in-out;
  -moz-transition: opacity 5s ease-in-out;
  -o-transition: opacity 5s ease-in-out;
  transition: opacity 5s ease-in-out;
}
.foregroundImg {
  opacity: 1;
}
.foregroundImg.transparent {
  opacity: 0;
}
.Background_onclick {
  cursor: pointer;
}
#comebacktomorrow {
  color: rgba(255, 255, 255, 0.787);
  font-family: "Helvetica Neue", sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -1px;
  line-height: 1;
  text-align: center;
  text-shadow: 0px 2px 3px rgb(77, 77, 77);
  opacity: 0;
  -webkit-transition: opacity 5s ease-in-out;
  -moz-transition: opacity 5s ease-in-out;
  -o-transition: opacity 5s ease-in-out;
  transition: opacity 5s ease-in-out;
}
#comebacktomorrow.visible {
  opacity: 1;
}

@media only screen and (max-width: 935px) {
  h1 {
    font-size: 25px;
    letter-spacing: -1px;
  }
  .button {
    padding: 13px 15px;
  }
}
</style>
