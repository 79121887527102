import { createStore } from "vuex";

export default createStore({
  state: {
    prefix: "@/assets/content/",
    imageMap: {
      1: "Radfahren",
      2: "Obstwanderung",
      3: "Boxenstop",
      4: "Winterbereit",
      5: "3 Wetter Taft",
      6: "Grinswettbewerb",
      7: "Letzter Abend",
      8: "Schlürf",
      9: "Öhm",
      10: "Oslo",
      11: "Biker",
      12: "Ansturm",
      13: "Grinse Keks",
      14: "Birthday",
      15: "Familienbild",
      16: "Lauter Als Das Wasser",
      17: "Lichtet Den Anker",
      18: "Trolle Suchen",
      19: "Vancouver Flughafen",
      20: "Blauzahn",
      21: "Mampf",
      22: "Apfelmassaker",
      23: "Tja Kein Strand",
      24: "Raus schwimmen",
      25: "Mamas Brille",
      26: "Ausgehen",
      27: "Drache",
      28: "Keks Aufessen",
      29: "Three Dogs",
      30: "Regenbogen",
      31: "Vikinger",
      32: "Erstmal Pause",
      33: "Grrr",
      34: "Apfel Essen",
      35: "Aufgegessen Werden",
      36: "Kalt",
      37: "Matsch Check",
      38: "Grillwürstchen",
      39: "The Girls",
      40: "Regen In Bergen",
      41: "Wintersonne",
      42: "Baden Ist Schön",
      43: "Mädel",
      44: "Thats Life",
      45: "Bärenjagt",
      46: "Riesenrad",
      47: "Rüdersdorf", // 16.02
      48: "Trampolin",
      49: "Approved By Edith",
      50: "Natur",
      51: "Besties",
      52: "Gischt",
      53: "Roter Panda",
      54: "Werwolf",
      55: "Unterm Wasserfall",
      56: "Neiin",
      57: "Faltenglättung",
      58: "Taucherin",
      59: "Grubenkind",
      60: "Wie Gott In Frankreich",
      61: "Bei Opa Gibts Eis", //02.03
      62: "Tag Am Meer",
      63: "Ausblick",
      64: "Is Das Normal",
      65: "Stumpf",
      66: "Matschweg",
      67: "Nach dem Baden",
      68: "Bääh",
      69: "Zunge",
      70: "Name The Band",
      71: "Windy",
      72: "Nachdenklich",
      73: "Poser",
      74: "Nur Ein Bischen Regen",
      75: "Gruselig",
      76: "Augenknoten",
      77: "Streuselberg",
      78: "Familiengrinsen",
      79: "Immer Fotos",
      80: "Zieh",
      81: "Gerümpel",
      82: "Backfee",
      83: "Post Arzt Routine",
      84: "Strandtänzerin",
      85: "Farm",
      86: "Prisma",
      87: "Wo Sind Die Bären",
      88: "Ranzen",
      89: "Soo Klein",
      90: "Die Steine Gehorchen Mir",
      91: "Ching",
      92: "Auf Dem Forcki",
      93: "Gemse",
      94: "Stimmung",
      95: "Chillen An Der Bib",
      96: "Narnia",
      97: "Attitüde",
      98: "Freundchen",
      99: "Badeurlaub",
      100: "Balanceakt",
      101: "Ankerdiebstahl",
      102: "Grimmig",
      103: "Katzen Mama",
      104: "verwirrt",
      105: "Gelassen Beerig",
      106: "Arrrr",
      107: "Urlaub", // 17.04.22
      108: "Pilz",
      109: "Steinig",
      110: "Da So Irgendwo",
      111: "Angry Butterfly",
      112: "Eselei",
      113: "Profilprofi",
      114: "der Hut",
      115: "Auf Den Klippen",
      116: "Giraffen",
      117: "Immer Diese Fotos",
      118: "Anschnallen",
      119: "Why So Sad",
      120: "Lasst Uns Lesen Im Buch Der Weisheit",
      121: "Künstlerin",
      122: "Grüne Brücke",
      123: "Faulenzen",
      124: "Wet Skyline",
      125: "3 Fragezeichen",
      126: "Am Kienberg",
      127: "Fuchsreiterin",
      128: "Spinnennetz",
      129: "Gelangweilt",
      130: "Lego",
      131: "Mikkel",
      132: "Teegeschütte",
      133: "Frisur",
      134: "Besenmodel",
      135: "Im Gletscher",
      136: "Im Gletscher 2",
      137: "Quatsch",
      138: "Ich Hasse Diesen Wald",
      139: "Sommerurlaub",
      140: "Bananengeist",
      141: "Minie",
      142: "Kuchen",
      143: "Regenwald",
      144: "Bärlin",
      145: "Konstruktion",
      146: "Muffin",
      147: "Flohmarkt",
      148: "Die Wand",
      149: "Getier",
      150: "Breakfast At Wohnwagen",
      151: "Singing In The Rain",
      152: "Basedow Garten",
      153: "Der Baum",
      154: "Hoch Oben",
      155: "Die Lagune",
      156: "Grüne Schaukel",
      157: "Ich Habe Eine Banane",
      158: "Run",
      159: "Sie Ist SOO Schrecklich",
      160: "Hippie",
      161: "Beach",
      162: "Stachelschwein",
      163: "Waaltastisch",
      164: "Haarologinnen",
      165: "Gruselgefahr",
      166: "Schlaf Der Gerechten",
      167: "Waldspaziergang",
      168: "4ter Geburtstag",
      169: "5ter Geburtstag",
      170: "Centerparks",
      171: "Himbeeren",
      172: "Dinner",
      173: "Breisgau",
      174: "Industrial",
      175: "Walnussstrasse",
      176: "Bonbon",
      177: "Dann Halt Apfel",
      178: "Hut",
      179: "Ich Mach Dich Messer",
      180: "Wassergefälle",
      181: "Abgehangen",
      182: "Spielen",
      183: "Blätter",
      184: "Blick Aufs Meer", // 03.07.22
      185: "Im Rad",
      186: "Vorschule",
      187: "Kofferraumparty",
      188: "Hör Nix",
      189: "Basedow Konzert",
      190: "Schlagt Mich Zum Ritter",
      191: "Flussfamilie",
      192: "Katzenbabies",
      193: "Ivenak", //12.07.22
      194: "Zuckerwatte",
      195: "Winzig",
      196: "Winterstrand",
      197: "Opa Oslo",
      198: "Schlitzohr SpitzOhr",
      199: "Bastelbett",
      200: "Verkehrslotse",
      201: "Knuddeln",
      202: "Ostsee",
      203: "Foto Grinsen",
      204: "Basteln Im Zoo",
      205: "Päuschen",
      206: "Ohringe Marke Eigenbau",
      207: "Baumwipfelquatsch",
      208: "Kalkofen",
      209: "Wind Im Gesicht",
      210: "Pimpernella Und Robot",
      211: "Blumenkind",//30.07
      212: "Moment",
      213: "Klettergerüst",
      214: "Penny In Bergen",
      215: "Daumen",
      216: "On The Road",
      217: "Baumarktexkursion",
      218: "Pinguine",
      219: "Meloni",
      220: "Herz",
      221: "Das Kleid",
      222: "Meer In Der Luft",
      223: "Vorsicht Sie Beißt",
      224: "Klammeräffchen",
      225: "Pizza Macht Glücklich",
      226: "Tadaa", //14.08.22
      227: "Lieblingsbeschäftigung",
      228: "Wiederschaukeln",
      229: "S_C_H_A_U_K_E_L_N",
      230: "Heller Die Glocken",
      231: "Fuchs", //19.08
      232: "Einschulungsgrinsen",
      233: "Baumschmuck",
      234: "Eisopa",
      235: "Der Song",
      236: "Der Song 2",
      237: "Schalk",
      238: "Haus Mit Rädern",
      239: "Eiszeit",
      240: "Da So",
      241: "Felsenbild",
      242: "Schlafen In Basedow",
      243: "Nicht Gut Kirschen Essen",
      244: "En Garde",
      245: "Das Letzte Hochbett",
      246: "Farben Des Regenbogens",
      247: "Noch Lächeln Sie",
      248: "Gehölz",
      249: "Batgirl",
      250: "Schlafwagen",
      251: "Der Turm",
      252: "Sandburg",
      253: "Bastelstunde",
      254: "Fruit Ninjas",
      255: "Natur 2",
      256: "Schnute", // 13.09
      257: "Erste Karateprüfung",
      258: "Samurai",
      259: "Was Sucht Sie",
      260: "Der Tag An Dem Floyd Kam",
      261: "Schminke",
      262: "Seebrücke Seelin",
      263: "Fototanten",
      264: "Döner",
      265: "Einschulung",
      266: "Gartenzimmer",
      267: "Malen",
      268: "Spiegelbilder",
      269: "KünstlerPrinzessin",
      270: "Mund Zu Voll Genommen",
      271: "Gärtnerinnen",
      272: "Futtern",
      273: "Seifenblasenkanone",
      274: "Lina",
      275: "Ziegen",
      276: "Pizza Teilen-NÖÖ",
      277: "Die Burg",
      278: "Breakfast At Granddad",
      279: "Die Drachen",
      280: "Monster",
      281: "Auf Den Preis Einigen",
      282: "Strandkorb",
      283: "Sumpfkühe",
      284: "Krumme Bäume",
      285: "Löwenzahnohr",
      286: "Mietzekatze",
      287: "Quallen",
      288: "Cousin Und Cousine",
      289: "Das Boot",
      290: "Man Chillt Wo Es Geht",
      291: "Liefervampir",
      292: "Zuckerperlen",
      293: "Dschungel",
      294: "Maid Auf Dem Felsen",
      295: "Das Duell",
      296: "Mufasa Das Eis Und Ich",
      297: "Princessed",
      298: "Salon",
      299: "Tram In Leipzig",
      300: "Frech",
      301: "Dinotastisch",
      302: "Zöpfe",
      303: "Radausflug",
      304: "Guck Mal Mein Profil",
      305: "Sie Wissen Sich Zu Verteidigen",
      306: "Speedjunkies",
      307: "Geburtstagsparty",
      308: "Wolkentupfer",
      309: "Dicke Backen",
      310: "Zapzaraptor",
      311: "Garten Lunch",
      312: "Wohin Gehts",
      313: "Fußspuren",
      314: "Ganz Schön Frech",
      315: "Schnurbart",
      316: "Warum Die Socke",
      317: "Geralt Und Roach",
      318: "Bettpickel",
      319: "Fahradhelm Probe",
      320: "Julius",
      321: "Pho",
      322: "Fernseheulen Aber Heimlich",
      323: "Spaziergang",
      324: "Gehen Sie Von Mir Runter",
      325: "Really",
      326: "Warum",
      327: "Geschenketag",
      328: "Zeltbauen",
      329: "Fischspezialitäten Mundt",
      330: "Chipsaugen",
      331: "Dich Kriegen Wir Auch Noch",
      332: "Hier Noch Ein Zopf",
      333: "Fussbad",
      334: "Durch Den Kiez",
      335: "Wunderkerzen",
      336: "Der Vogel Ist Immer Dabei",
      337: "Schmetterling",
      338: "Wo Sind Die Ziegen",
      339: "Füße Im Sand",
      340: "Schnitzelparty",
      341: "Wasserfall",
      342: "Die Band",
      343: "Das Blaue Pony",
      344: "Dancing With The Mountains",
      345: "Sithlördchen",
      346: "Fliegen",
      347: "Schwung Im Reifen",
      348: "Weihnachtsmarkt",
      349: "Boot fahren",
      350: "Brille",
      351: "Am See",
      352: "auf Wilden Gewässern", //18.12.
      353: "Backshop",
      354: "Beste",
      355: "der Rote Blitz", //20.12
      356: "das waren noch Zeiten",
      357: "erster Maiskolben",
      358: "sleeping beauty",
      359: "Erster Weihnachtsbaum", //24.12
      360: "zweiter Weihnachtsbaum",
      361: "Christmas Horsies",
      362: "Brille Fielmann Ich Mein Edith",
      363: "Hängepartie",
      364: "Happy Beach",
      365: "Angeberinnen", // 31.12.
      366: "5 Eagles",
      6666: "eda", //something went wrong!
    },
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
